/* global angular */

'use strict';

angular.module('managerApp').controller('IPController', (
        $scope, $location, toast, settings) => {

    $scope.items = [];
    $scope.item = null;

    function getIPs() {
        settings.ip.get().then((data) => {
            $scope.items = data;
            $scope.$apply();
        }).catch((err) => {
            $scope.error = err;
        });
    }

    $scope.add = (ip = null) => {
        if (ip !== null) {
            settings.ip.add({
                ip: ip
            }).then((response) => {
                toast.show(response.message, 'success');
            }).catch( (err) => {
                toast.show(err.message, 'error');
            });
        }
    };

    $scope.delete = (ip) => {
        settings.ip.delete(ip).then((response) => {
            toast.show('L\'adresse IP "' + ip + '" a bien été supprimée.', 'success');
            getIPs();
        }).catch((err) => {
            toast.show('Une erreur est survenu lors de la suppression' +
                    ' de ce pack.');
        });
    };

    getIPs();

});
